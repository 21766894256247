import axios from 'axios'
import store from '@/store'
import Vue from 'vue' // 導入實例
// import aes from '@/assets/utils/aes'
// import App from '@/main' // 導入所有掛載在main中的實例

// 建立默認設定
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    sn: process.env.VUE_APP_SN,
  },
  transformRequest: [
    function (data) {
      let src = ''
      for (let item in data) {
        src += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&'
      }
      return src
    },
  ],
})

// 請求攔截
instance.interceptors.request.use((config) => {
  Vue.prototype.$Progress.start() // 進度條開始

  if (config.kcval) {
    config.data.kcval = Vue.prototype.$aes.encrypt(
      store.state.login.userInfo.enval1,
      store.state.login.userInfo.enval2,
      store.state.login.userInfo.stoken
    )
  }
  return config
})

// 響應攔截
instance.interceptors.response.use(
  (res) => {
    Vue.prototype.$Progress.finish() // 進度條結束

    return res
  },
  (error) => {
    // 錯誤時的訊息
    Vue.prototype.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
  }
)

export default instance
