import { extend } from 'vee-validate' // 表單驗證
import * as rules from 'vee-validate/dist/rules' // 安裝所有預設規則
import { localize } from 'vee-validate' // 安裝語言環境
import zh_TW from '@/assets/json/zh_TW.json' // 安裝繁體中文
import { setInteractionMode } from 'vee-validate' // interaction modes

// setInteractionMode('lazy') // 全域設定 lazy 模式，Validates on change or blur.

// 安裝所有預設規則
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

localize('zh_TW', zh_TW)

// 自定義表單驗證規則
extend('positive', {
  validate: (value) => value === 'example', // 驗證函式
  message: 'This is not the magic word', // 錯誤訊息
})

// 台灣手機號碼驗證
extend('TW_phoneNum', {
  validate: (value) => {
    let reg = /^09[0-9]{8}$/
    return reg.test(value)
  },
  message: '須為 09 開頭的 10 位數字',
})

// 國際手機號碼驗證
// extend('CODE_phoneNum', {
//   validate: (value) => {
//     let reg = /^\+[0-9]*$/gm
//     return reg.test(value)
//   },
//   message: '{_field_} 須包含區碼和數字',
// })

extend('password', {
  validate: (value) => {
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
    return reg.test(value)
  },
  message: '須為8~16個字元，並混合大、小寫英文字母和數字',
})
