import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import getWebData from './modules/getWebData'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activating: '', // 開啟中的組件
    mobile_menu: false, // 手機選單開關
    theme_edit_menu: false, // 編輯佈景主題選單開關
    drawer: false, // drawer選單開關
    overlay: false, // 遮罩開關
  },
  mutations: {
    MOBILE_MENU_TOGGLER(state, payload) {
      state.mobile_menu = !state.mobile_menu
      state.activating = payload.name
    },
    THEME_EDIT_MENU_TOGGLER(state, payload) {
      state.theme_edit_menu = !state.theme_edit_menu
      state.activating = payload.name
    },
    DRAWER_TOGGLER(state, payload) {
      state.drawer = !state.drawer
      state.activating = payload.name
    },
    OVERLAY_TOGGLER(state, payload) {
      state.overlay = payload
    },
  },
  actions: {
    closeOverlay({ commit, state }) {
      // 關閉遮罩並關閉開啟中的功能
      commit('OVERLAY_TOGGLER', false)
      commit(state.activating, {
        name: '',
      })
    },
  },
  modules: {
    login,
    getWebData,
  },
})
