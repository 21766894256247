import Vue from 'vue'

let noti

export default {
  // position 和 color 使用時傳入 undefined，會使用預設值
  // 1.標題 2.內文 3.顏色 4.持續時間 5.寬度 6.位置 7.持續條
  openNoti(title, text, color = 'success', duration = 3000, width = 'default', position = 'bottom-center', progress = 0) {
    return new Promise((res) => {
      noti = Vue.prototype.$vs.notification({
        color,
        position,
        width,
        title,
        text,
        progress,
        duration,
        // 關閉時回傳 destroy
        onDestroy: () => {
          res('destroy')
        },
      })
    })
  },
}
