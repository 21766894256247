<template>
  <vue-skeleton-loader
    :width="width"
    :height="height"
    :animation="animation"
    :rounded="rounded"
    :radius="radius"
    :type="type"
    :color="color"
  />
</template>

<script>
// https://www.npmjs.com/package/skeleton-loader-vue
import VueSkeletonLoader from 'skeleton-loader-vue'
export default {
  name: 'skeleton',
  components: { VueSkeletonLoader },
  props: {
    width: {
      type: String | Number,
      default: '100%',
    },
    height: {
      type: String | Number,
      default: '40px',
    },
    animation: {
      type: String,
      default: 'fade',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'rect',
    },
    radius: {
      type: String | Number,
      default: 12,
    },
    color: {
      type: String,
      default: 'rgba(0,0,0,0.05)',
    },
  },
}
</script>

<style></style>
