// import Vue from 'vue'
import CryptoJS from 'crypto-js'
require('dotenv').config()

const key2 = process.env.VUE_APP_KEY2
const CBCIV2 = process.env.VUE_APP_CBCIV2

export default {
  encrypt(enval1, enval2, stoken) {
    const key = CryptoJS.enc.Utf8.parse(enval1 + key2)
    const iv = CryptoJS.enc.Utf8.parse(enval2 + CBCIV2)

    let data = `${enval1},${enval2},${stoken}`

    let result = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString()

    // result 中若有 + 要改成 *
    return result.replaceAll('+', '*')
  },

  // decrypt(data) {
  //   let result = CryptoJS.AES.decrypt(data, key, {
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7,
  //   }).toString()

  //   return result
  // },
}
