import Cookies from 'js-cookie'

export default {
  // modules 內只有 state 是區域，其他會混進全域。所以需加上 namespaced
  namespaced: true,

  state: {
    param: {
      sname: Cookies.get('sname') || '', // 商店名稱
      wlink: Cookies.get('wlink') || '', // 網址
      pclogo: Cookies.get('pclogo') || '',
      mblogo: Cookies.get('mblogo') || '',
      isenable: Cookies.get('isenable') || '',
      uname: Cookies.get('uname') || '',
    },
    auth: {
      auth: Cookies.get('auth') || '',
    },
  },

  mutations: {
    UPDATE_COOKIE(state, payload) {
      // for (let key in payload) {
      //   state.param[key] = payload[key]
      //   Cookies.set(key, payload[key], { expires: 7 })
      // }

      let param = payload.param
      let auth = payload.auth

      for (let key in param) {
        state.param[key] = param[key]
        Cookies.set(key, param[key], { expires: 7 })
      }

      for (let key in auth) {
        state.auth[key] = auth[key]
        Cookies.set(key, auth[key], { expires: 7 })
      }

      // Cookies.set('wlink', param.wlink, { expires: 7 })
      // Cookies.set('sname', param.sname, { expires: 7 })
      // Cookies.set('pclogo', param.pclogo, { expires: 7 })
      // Cookies.set('mblogo', param.mblogo, { expires: 7 })
      // Cookies.set('auth', auth, { expires: 7 })
    },
    LOG_OUT(state) {
      // 登出 清除所有cookie在param的值
      for (let key in state.param) {
        Cookies.remove(key)
        state.param[key] = ''
      }
      for (let key in state.auth) {
        Cookies.remove(key)
        state.auth[key] = ''
      }
    },
  },

  actions: {},
  getters: {},
}
