import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/global.scss' // 全域css
import SvgIcon from '@/components/svgIcon.vue' // svg套件
import errorDiv from '@/components/errorDiv.vue' // 表單驗證錯誤訊息
import VueProgressBar from 'vue-progressbar' // https://github.com/hilongjw/vue-progressbar
import '@/assets/css/tailwind.css'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax 預設 styles
import '@/assets/css/vuesax_setting.scss' //Vuesax 自定義 styles
import { ValidationProvider } from 'vee-validate' // 表單驗證
import { ValidationObserver } from 'vee-validate' // 多項表單驗證
import VueDOMPurifyHTML from 'vue-dompurify-html' // 防止v-html xss攻擊
import '@/assets/js/validation.js' // 自定義表單驗證規則
import axios from './assets/utils/axios'
import myVS from './assets/utils/customize_vuesax' //自定義的客製化function
import aes from './assets/utils/aes.js' // kcval
import CKEditor from '@ckeditor/ckeditor5-vue2'
import filter_currency from './assets/utils/filter_currency'
Vue.filter('currency', filter_currency) // 全域啟用 filter
// 自己的components
import myTextarea from './components/ui_components/textarea.vue'
Vue.component(myTextarea.name, myTextarea)
import skeleton from './components/skeleton.vue'
Vue.component(skeleton.name, skeleton)
//
Vue.prototype.$axios = axios
Vue.prototype.$aes = aes
Vue.prototype.$myVS = myVS // 掛載到vue實例中

Vue.component('ValidationProvider', ValidationProvider) // 單項表單驗證
Vue.component('ValidationObserver', ValidationObserver) // 多項表單驗證
Vue.component('errorDiv', errorDiv) // 表單驗證錯誤訊息
Vue.use(VueDOMPurifyHTML) // 防止v-html xss攻擊 用法：v-html="" -> v-dompurify-html=""

Vue.use(CKEditor)

Vue.use(Vuesax, {
  // options here
  colors: {
    primary: 'rgb(51, 51, 51)',
    success: 'rgb(64,124,205)',
    danger: 'rgb(226,120,83)',
    warning: 'rgb(255,215,118)',
    dark: 'rgb(51, 51, 51)',
  },
})

Vue.use(VueProgressBar, {
  color: 'rgb(64,124,205)',
  failedColor: 'rgb(226,120,83)',
  thickness: '3px',
  transition: {
    speed: '0.8s',
    opacity: '0s',
    termination: 1000,
  },
})

// 统一注册svg-icon组件
Vue.component('svg-icon', SvgIcon)
// 遍历require.context的返回模块，并导入
const requireAll = (requireContext) => requireContext.keys().map(requireContext)
// import所有符合条件的svg 三个参数：文件夹、是否使用子文件夹、正则
const req = require.context('@/assets/icons/svg', true, /\.svg$/)
requireAll(req)

Vue.config.productionTip = false

export default new Vue({
  ...App, // 導出App實例
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
