<template>
  <span class="flex items-center space-x-2 absolute right-0">
    <!-- v-if="$slots.default[0].text" // 若slot有值才顯示icon -->
    <svg-icon v-if="$slots.default[0].text" icon-class="caution" class="icon"></svg-icon>

    <span class="errorSpan">
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {}
</script>

<style scoped>
.icon {
  @apply w-4 h-4 !important;
  fill: #ff0000;
}

.errorSpan {
  font-size: 12px;
  color: #ff0000;
}
</style>
