import Cookies from 'js-cookie'

export default {
  // modules 內只有 state 是區域，其他會混進全域。所以需加上 namespaced
  namespaced: true,

  state: {
    userInfo: {
      stoken: Cookies.get('stoken') || '', // 商店代碼
      // uname: Cookies.get('uname') || '', // 姓名
      bauth: Cookies.get('bauth') || '', // 權限
      // sname: Cookies.get('sname') || '', // 商店名稱
      // isenable: Cookies.get('isenable') || '', // 開通狀態0未開通1已開通
      enval1: Cookies.get('enval1') || '', // 6bit key1
      enval2: Cookies.get('enval2') || '', // 6bit key2
      // sex: Cookies.get('sex') || '',
    },
  },

  mutations: {
    UPDATE_COOKIE(state, payload) {
      for (let key in payload) {
        state.userInfo[key] = payload[key]
        Cookies.set(key, payload[key], { expires: 7 })
      }
    },
    LOG_OUT(state) {
      // 登出 清除所有cookie在userInfo的值
      for (let key in state.userInfo) {
        Cookies.remove(key)
        state.userInfo[key] = ''
      }
    },
  },

  actions: {},
  getters: {},
}
